
import {
  getDeviceMapInfos
} from "../../../../api/api";
import session from "../../../../store/store";
import until from '../../../../until/until'
import mapInfo from './map-info'
export default {
  data() {
    return {
      deviceType:'',//设备状态
      deviceTypeOption:[
        {value: 1,label: '穿戴设备'},
        {value: 2,label: '智能家居'},
        {value: 3,label: '健康设备'}
      ],
      infoWindow: null, //窗体
      map: null,//地图实例对象
      iconRed:null,
      iconBlue:null,
      role: 0,
      currentPage: 0,
      size: 50,
      total: 0,//总数
      totalNum:0,//临时总数


      validTotal:0,//有效总数
      validOnLine:0,//在线
      validOffLine:0, //离线
      noLine:0, //未知
    }
  },
  mounted() {
    this.initData()
    this.initMap()
  },
  unmounted() {
    if(this.map != null) {
      this.map.destroy()
      this.map = null
    }
  },
  methods: {
    /**
     * 地图初始化
     */
    initMap() {
      this.map = new AMap.Map('map', {
          resizeEnable: true,
          zoom: 17,
          pitch: 50,
      });
       // 信息窗口实例
      this.infoWindow = new AMap.InfoWindow({
        // isCustom: true,  //使用自定义窗体
        offset: new AMap.Pixel(7, 0),
      });
      //自定义标注样式 
      this.iconRed = new AMap.Icon({
        image : require('../../../../assets/img/point-red.png'),
        size : new AMap.Size(14,23)
      });
      this.iconBlue = new AMap.Icon({
        image : require('../../../../assets/img/point-blue.png'),
        size : new AMap.Size(14,23)
      });
  

    },

    initData() {
      this.role = session.getLoginInfo().accountType;
      this.getDeviceMapInfos()
    },

    getDeviceMapInfos() {
      let param = {
        unitUuid:session.getLoginInfo().unitUuid,
        role:this.role,
        page:this.currentPage,
        size:this.size,
      }
      getDeviceMapInfos(param).then(res => {
        if (res.data.code == 200) {
          this.total = res.data.count;
          this.totalNum = this.totalNum + res.data.dataInfo.length
          this.currentPage++
          this.addDeviceMarker(res.data.dataInfo)
          this.setTime()
        } 
      })
    },

    setTime() {
      if(this.totalNum >= this.total) {
        return
      }
      setTimeout(() => {
        this.getDeviceMapInfos();
      }, 1000);
    },

    addDeviceMarker(dataList) {
      var markers = []
      dataList.forEach(data => {
        this.validTotal++
        if((data.lat != '0') ||(data.lng != '0')) {
          var marker = null
          if(data.onLine == 1) {
            marker = new AMap.Marker({
              position: new AMap.LngLat(data.lng, data.lat),//位置
              icon: this.iconBlue, // 添加 Icon 实例
            })
            data.onLineStr = "在线"
            this.validOnLine++
          }else {
            marker = new AMap.Marker({
              position: new AMap.LngLat(data.lng, data.lat),//位置
              icon: this.iconRed, // 添加 Icon 实例
            })
            data.onLineStr = "离线"
            this.validOffLine++
          }

          // this.validTotal = this.validOnLine + this.validOffLine

          if(data.elec == 0) {
            data.elec = '--'
          }else {
            data.elec = data.elec + '%'
          }

          if (until.isNULL(data.userName)) {
            data.userName = '未绑定'
          }

          data.address = data.address.replaceAll("#","");

          marker.on('click', () => {
            if(data.deviceType == "穿戴设备") {
              this.infoWindow.setContent(mapInfo.getWearDeviceContent(data));
            }else {
              this.infoWindow.setContent(mapInfo.getSmartDeviceContent(data));
            }
            if(!this.infoWindow.getIsOpen()) {
                this.infoWindow.open(this.map, marker.getPosition());
            }else {
              this.infoWindow.close();
            }
          });
          markers.push(marker)
        }else {
          this.noLine++
        }
      });

      if(markers.length > 0) {
        var overlayGroups = new AMap.OverlayGroup(markers);
        this.map.add(overlayGroups);
        this.map.setFitView();
      }
      
    },
    
    /**
     * 地址栏改变
     */
    adresschange(e) {
      console.info(e)
    }

  }
}