<template>
  <div class="page">
    <div class="selBox">

      <!-- <el-select v-model="deviceType" class="elsel" placeholder="设备类型" size="small">
        <el-option v-for="item in deviceTypeOption" :key="item.value" :label="item.label" :value="item.value"></el-option>
      </el-select>
      <span style="margin-left:5px;"></span>
      <el-select v-model="deviceType" class="elsel" placeholder="设备名称" size="small">
        <el-option key="aaa" label="aaa" value="aaa">
        </el-option>
      </el-select>
      <span style="margin-left:5px;"></span>
      <el-select v-model="deviceType" class="elsel" placeholder="设备型号" size="small">
        <el-option key="aaa" label="aaa" value="aaa">
        </el-option>
      </el-select> -->

        <el-image style="margin-left: 5px; width: 20px; height: 20px;text-align: center;"  :src="require('../../../assets/img/total.png')"></el-image>
        <span style="margin-left:5px;">总数[{{validTotal}}]</span>
        <el-divider direction="vertical"></el-divider>
        <el-image style="margin-left: 15px; width: 20px; height: 20px;text-align: center;"  :src="require('../../../assets/img/online.png')"></el-image>
        <span style="margin-left:5px;color: blue;">在线[{{validOnLine}}]</span>
        <el-divider direction="vertical"></el-divider>
        <el-image style="margin-left: 15px; width: 20px; height: 20px;text-align: center;"  :src="require('../../../assets/img/offline.png')"></el-image>
        <span style="margin-left:10px;color:red;">离线[{{validOffLine}}]</span>
        <el-divider direction="vertical"></el-divider>
        <el-image style="margin-left: 15px; width: 20px; height: 20px;text-align: center;"  :src="require('../../../assets/img/noline.png')"></el-image>
        <span style="margin-left:10px;color:gray;">未知[{{noLine}}]</span>
    </div>
    <!-- <div class="addressBox">
      <ls-address @adr-change="adresschange"  style=" display: flex;justify-content: space-between;width:100%;" ></ls-address>
    </div> -->
    <div class="box">
      <div id="map" class="amap-wrapper"></div>
    </div>

    
  </div>
</template>

<script src='./js/device-map.js'></script>

<style scoped>
.sel {
  margin: 0px 10px 0px 10px;
}
#layerbox {
    background: #fff;
    z-index: 112;
    -webkit-border-radius: 1px;
    -moz-border-radius: 1px;
    -o-border-radius: 1px;
    border-radius: 3px;
}
.selBox {
  position: absolute;
  top: 5px;
  right: 20px;
  z-index: 12;
  background: #fff;
  padding: 10px;
  border-radius: 3px;
  font-size: 20px;

  box-shadow: 0 2px 2px rgba(0,0,0,.15);
  text-align: center;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-image: url('../../../assets/img/point-red.png'); */
  /* width: 50%; */
}
.elsel {
  width: 100px;
  /* margin-left: 5px; */
}
.addressBox {
  position: absolute;
  top: 0px;
  right: 20px;
  z-index: 1;
  /* background-color: white; */
  padding: 5px;
  border-radius: 8px;
  width: 400px;
 
}

.box {
  width: 100%;
  height: 100%;
  position: absolute;
}
.amap-wrapper {
  width: 100%;
  height: 100%;
}

</style>
<style>
  .amap-info-content {
    overflow: hidden;
    padding: 0px;
  }
</style>